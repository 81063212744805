import React, { lazy } from 'react';
import { MantineProvider, ColorSchemeProvider } from '@mantine/core';
import { Route, Routes } from 'react-router-dom';


//~ ///////////////////////////////////////////**************LAYOUT*************///////////////////////////////////////////////////////////
import Layout from './components/LayoutGlobals/Layout';
import AlreadyPaid from './Screens/404/AlreadyPaid';


//~ ///////////////////////////////////////////**************ROOT*************///////////////////////////////////////////////////////////
const HomeLayout = lazy(() => import('./Screens/Home/HomeLayout'));


//~ ///////////////////////////////////////////**************SCREENS*************///////////////////////////////////////////////////////////
const DPLayout = lazy(() => import('./Screens/MarketReport/Layout/DPLayout'));
const Missing = lazy(() => import('./Screens/404/Missing'));
const Contact = lazy(() => import('./Screens/Contact/Contact'));


//~ ///////////////////////////////////////////**************CREATE DATA PROFILE SCREENS*************///////////////////////////////////////////////////////////
const AddressInput = lazy(() => import('./Screens/Property_Search/AddressLookup/AddressInput'));
const DashLayout = lazy(() => import('./Screens/Property_Search/Layout/DashLayout'));
const MapModal = lazy(() => import('./Screens/Property_Search/AddressLookup/MapModal'));


//~ ///////////////////////////////////////////**************MARKET REPORTS SCREENS*************///////////////////////////////////////////////////////////
// const MPLayout = lazy(() => import('./components/LayoutGlobals/MPLayout'));
const CheckReportId = lazy(() => import('./components/Auth/CheckReportId'));
const Overview = lazy(() => import('./Screens/MarketReport/Sections/KeyTrends/KTLayout'));
const MapsLayout = lazy(() => import('./Screens/MarketReport/Sections/Maps/MapsLayout'));
const PopLayout = lazy(() => import('./Screens/MarketReport/Sections/Population/PopLayout'));
const EmploymentIncomeLayout = lazy(() => import('./Screens/MarketReport/Sections/Employment/EmploymentLayout'));
const EducationLayout = lazy(() => import('./Screens/MarketReport/Sections/Education/EducationLayout'));
const HousingLayout = lazy(() => import('./Screens/MarketReport/Sections/Housing/HousingLayout'));


//~ ///////////////////////////////////////////**************AUTH SCREENS*************///////////////////////////////////////////////////////////
const Register = lazy(() => import('./Screens/AuthScreens/Register'));
const Login = lazy(() => import('./Screens/AuthScreens/Login/Login'));
const RequireAuth = lazy(() => import('./components/Auth/RequireAuth'));
const RequireSignupForCheckout = lazy(() => import('./components/Auth/RequireSignupForCheckout'));
const PersistLogin = lazy(() => import('./components/Auth/PersistLogin'));
// const CheckPlanStatusForCheckout = lazy(() => import('./components/Auth/CheckPlanStatusForCheckout'));


//~ ///////////////////////////////////////////**************USER PROFILE SCREENS*************///////////////////////////////////////////////////////////
const RequireProfileAuth = lazy(() => import('./components/Auth/RequireProfileAuth'));
const ProfileLayout = lazy(() => import('./Screens/Profile/Layout/ProfileLayout'));
const Settings = lazy(() => import('./Screens/Profile/Settings/Layout/Settings'));
const DataProfiles = lazy(() => import('./Screens/Profile/Reports/Layout/Reports'));
const Dashboard = lazy(() => import('./Screens/Profile/DashBoard/Layout/Dashboard'));


//~ ///////////////////////////////////////**************PRICING SCREENS*************///////////////////////////////////////////////////////////
const PricingLayout = lazy(() => import('./Screens/Pricing/PricingLayout'));
const PricingPlans = lazy(() => import('./Screens/Pricing/Sections/PricingPlans'));
const CancelSubscription = lazy(() => import('./Screens/Pricing/Sections/CancelSubscription'));
const PlanCancelledSuccess = lazy(() => import('./Screens/Pricing/Sections/PlanCancelledSuccess'));


const CheckoutLayout = lazy(() => import('./Screens/StripeCheckout/CheckoutLayout'));
const CheckoutSuccess = lazy(() => import('./Screens/StripeCheckout/CheckoutSuccess'));


//~ ///////////////////////////////////////////**************PLATFORM*************///////////////////////////////////////////////////////////
const APILayout = lazy(() => import('./Screens/Platform/APIs/Layout'));
const ReportsLayout = lazy(() => import('./Screens/Platform/Reports/Layout'));


const Unsubscribe = lazy(() => import('./Screens/AuthScreens/Unsubscribe/Unsubscribe'));


const PdfTemplate = lazy(() => import('./Screens/Pdf_Report_Templates/Layout/ReportLayout'));

const PrivacyPolicy = lazy(() => import('./Screens/Policies/PrivacyPolicy'));

const ROLES = [
    'trial',
    'pro_monthly',
    'pro_yearly',
    'enterprise_monthly',
    'enterprise_yearly'
];

function App() {
    const colorScheme = 'light';

    return (
        <ColorSchemeProvider colorScheme={colorScheme}>
            <MantineProvider
                theme={{
                    colorScheme,
                    colors: {
                        pink: ['#A6ADC9', '#99A2C2', '#8C96BA', '#808BB3', '#6673A3', '#5C6999', '#54618C', '#54618C', '#454f73', '#3D4666'],
                    },
                    primaryColor: colorScheme === 'dark' ? 'orange' : 'pink',
                    cursorType: 'pointer',
                    fontFamily: ['Poppins, sans-serif', 'Inter, sans-serif']
                }}

                withGlobalStyles
                withNormalizeCSS
            >
                <Routes>
                    <Route element={<PersistLogin />}>
                        <Route path='/' element={<Layout />}>
                            <Route index element={<HomeLayout />} />


                            <Route path={'pricing'} element={<PricingLayout />}>
                                <Route path={'plans'} element={<PricingPlans />} />
                            </Route>

                            {/* <Route element={<CheckPlanStatusForCheckout allowedRoles={ROLES} />}> */}
                                <Route element={<RequireSignupForCheckout allowedRoles={ROLES} />}>
                                    <Route path={'checkout/:productId'} element={<CheckoutLayout />} />
                                </Route>
                            {/* </Route> */}

                            <Route path={'checkout-succeeded'} element={<CheckoutSuccess />} />

                            <Route element={<RequireAuth allowedRoles={ROLES} />}>
                                <Route path={'cancel-subscription'} element={<CancelSubscription />} />
                                <Route path={'plan-cancel-success'} element={<PlanCancelledSuccess />} />
                            </Route>

                            //*********************** PRODUCTS ******************************//
                            <Route element={<RequireAuth allowedRoles={ROLES} />}>

                                //! Address Lookup
                                <Route path='property-search' element={<DashLayout />}>
                                    <Route path='address-input' element={<AddressInput />} />
                                    <Route path='map-confirmation' element={<MapModal />} />
                                </Route>

                                //! Demographic API
                            </Route>

                            <Route element={<APILayout />} path='platform/demographic/api'>
                            
                            </Route>
                            <Route element={<ReportsLayout />} path='platform/reports/api'/>


                            {/*********************** CONTACT ******************************/}
                            <Route path='contact' element={<Contact />} />

                            {/*********************** 404 MISSING  ******************************/}
                            <Route path='*' element={<Missing />} /> {/* 404 Route */}

                            <Route path='already+paid' element={<AlreadyPaid />} />

                            {/*********************** AUTH SCREENS ******************************/}
                            <Route path='login' element={<Login />} />
                            <Route path='register' element={<Register />} />

                            <Route path='privacy-policy' element={<PrivacyPolicy />} />

                            <Route path='unsubscribe' element={<Unsubscribe />} />
                        </Route>


                        {/*********************** DEMOGRAPHIC PROFILES ******************************/}
                        <Route element={<CheckReportId />}>
                            <Route path='/market+report/:id' element={<DPLayout />}> {/* Dashboards */}
                                <Route path='key+trends' index element={<Overview />} />
                                <Route path='population' index element={<PopLayout />} />
                                <Route path='employment+income' index element={<EmploymentIncomeLayout />} />
                                <Route path='education' index element={<EducationLayout />} />
                                <Route path='housing' index element={<HousingLayout />} />
                                <Route path='map+views' index element={<MapsLayout />} />
                            </Route>
                        </Route>

                        {/*********************** USER PROFILES ******************************/}
                        <Route element={<RequireProfileAuth />}>
                            <Route path='profile/:id' element={<ProfileLayout />}>
                                <Route path='settings' element={<Settings />} />
                                <Route path='data+profiles' element={<DataProfiles />} />
                                <Route path='dashboard' element={<Dashboard />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path='pdf-report-template/:id' element={< PdfTemplate />} />

                </Routes>
            </MantineProvider>
        </ColorSchemeProvider>
    );
}

export default App;